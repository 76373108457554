<template>
	<div class="cards">
		<router-link to="/admProdutos" class="card hover mb-0">
			<div class="card-body p-2 text-center">
				<img class="mb-2" src="@/assets/admin/images/icons_home/iconeProdutos.png" height="75" width="75" alt="Icon">
				<h6 class="font-13">Produtos</h6>
			</div>
		</router-link>
		<router-link to="/admPedidos" class="card hover mb-0">
			<div class="card-body p-2 text-center">
				<span class="icon-warning" title="Pedidos pendentes" v-if="countPedidosPendentes > 0">{{ countPedidosPendentes }}</span>
				<img class="mb-2" src="@/assets/admin/images/icons_home/iconePedidos.png" height="75" width="75" alt="Icon">
				<h6 class="font-13">Pedidos</h6>
			</div>
		</router-link>
		<router-link to="/admConfiguracoes" class="card hover mb-0">
			<div class="card-body p-2 text-center">
				<img class="mb-2" src="@/assets/admin/images/icons_home/iconeConfiguracoes.png" height="75" width="75" alt="Icon">
				<h6 class="font-13">Configurações</h6>
			</div>
		</router-link>
		<router-link to="/admVendas" class="card hover mb-0">
			<div class="card-body p-2 text-center">
				<img class="mb-2" src="@/assets/admin/images/icons_home/iconeVendas.png" height="75" width="75" alt="Icon">
				<h6 class="font-13">Vendas</h6>
			</div>
		</router-link>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'AdmPágina_Inicial',
   computed: {
      ... mapState({
         countPedidosPendentes: state => state.countPedidosPendentes,
         dadosUsuario: state => state.dadosUsuario
      })
   },
	methods: {
		loop : function () {
			let ref = this;

			this.$store.dispatch('buscarPedidosPendentes').then(function () {
				setTimeout(() => {
					ref.loop()
				}, 300000);
			})
		}
	},
	created() {
		this.loop()

		require("@/assets/admin/css/metisMenu.min.css")
		require("@/assets/admin/js/metisMenu.min.js")
	}
 }

</script>

<style scoped>

.row {
	margin: 0;
}

.row>div {
	padding: 0;
}

.cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

</style>